import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6e66052b = () => interopDefault(import('../pages/account-requests/index.vue' /* webpackChunkName: "pages/account-requests/index" */))
const _015fe05e = () => interopDefault(import('../pages/ai-reports.vue' /* webpackChunkName: "pages/ai-reports" */))
const _186b304e = () => interopDefault(import('../pages/bonus/index.vue' /* webpackChunkName: "pages/bonus/index" */))
const _36349063 = () => interopDefault(import('../pages/boost-all.vue' /* webpackChunkName: "pages/boost-all" */))
const _1a5512d2 = () => interopDefault(import('../pages/bug-reports.vue' /* webpackChunkName: "pages/bug-reports" */))
const _b23fbafa = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _c8ec9eb8 = () => interopDefault(import('../pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _720c9926 = () => interopDefault(import('../pages/feedback-funnel/index.vue' /* webpackChunkName: "pages/feedback-funnel/index" */))
const _0c1099e4 = () => interopDefault(import('../pages/ht-alerts.vue' /* webpackChunkName: "pages/ht-alerts" */))
const _7281c3a6 = () => interopDefault(import('../pages/invoices/index.vue' /* webpackChunkName: "pages/invoices/index" */))
const _4611667c = () => interopDefault(import('../pages/journal-edits/index.vue' /* webpackChunkName: "pages/journal-edits/index" */))
const _382fcffd = () => interopDefault(import('../pages/lock-logs.vue' /* webpackChunkName: "pages/lock-logs" */))
const _b06e8f56 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _dea792a4 = () => interopDefault(import('../pages/manager-stats/index.vue' /* webpackChunkName: "pages/manager-stats/index" */))
const _56c7b31c = () => interopDefault(import('../pages/manuals/index.vue' /* webpackChunkName: "pages/manuals/index" */))
const _0373c5e5 = () => interopDefault(import('../pages/message-reports.vue' /* webpackChunkName: "pages/message-reports" */))
const _0f815a90 = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _7cce454e = () => interopDefault(import('../pages/missed-payments/index.vue' /* webpackChunkName: "pages/missed-payments/index" */))
const _9c22eb66 = () => interopDefault(import('../pages/my-team/index.vue' /* webpackChunkName: "pages/my-team/index" */))
const _daa4104c = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _28367254 = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _786a85a0 = () => interopDefault(import('../pages/operator-details/index.vue' /* webpackChunkName: "pages/operator-details/index" */))
const _f385503c = () => interopDefault(import('../pages/operator-traffic-dashboard/index.vue' /* webpackChunkName: "pages/operator-traffic-dashboard/index" */))
const _6f6b02ef = () => interopDefault(import('../pages/payment-updates.vue' /* webpackChunkName: "pages/payment-updates" */))
const _35af7a56 = () => interopDefault(import('../pages/pl-stats/index.vue' /* webpackChunkName: "pages/pl-stats/index" */))
const _58ae64d6 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _784b3231 = () => interopDefault(import('../pages/profiles/index.vue' /* webpackChunkName: "pages/profiles/index" */))
const _36c8bf27 = () => interopDefault(import('../pages/rejection-categories/index.vue' /* webpackChunkName: "pages/rejection-categories/index" */))
const _28b9e1ac = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _54f934ce = () => interopDefault(import('../pages/stats/index.vue' /* webpackChunkName: "pages/stats/index" */))
const _4a65dc3a = () => interopDefault(import('../pages/top-triggers/index.vue' /* webpackChunkName: "pages/top-triggers/index" */))
const _1812ae04 = () => interopDefault(import('../pages/traffic-stats/index.vue' /* webpackChunkName: "pages/traffic-stats/index" */))
const _9490d27c = () => interopDefault(import('../pages/triggers/index.vue' /* webpackChunkName: "pages/triggers/index" */))
const _54c504bc = () => interopDefault(import('../pages/update-stats/index.vue' /* webpackChunkName: "pages/update-stats/index" */))
const _6fb67d91 = () => interopDefault(import('../pages/wink-replies/index.vue' /* webpackChunkName: "pages/wink-replies/index" */))
const _b954bd2c = () => interopDefault(import('../pages/manuals/new.vue' /* webpackChunkName: "pages/manuals/new" */))
const _3cee1148 = () => interopDefault(import('../pages/my-team/add.vue' /* webpackChunkName: "pages/my-team/add" */))
const _21b193d2 = () => interopDefault(import('../pages/my-team/pending.vue' /* webpackChunkName: "pages/my-team/pending" */))
const _34723ae6 = () => interopDefault(import('../pages/news/CreateNewsModal.vue' /* webpackChunkName: "pages/news/CreateNewsModal" */))
const _6072a898 = () => interopDefault(import('../pages/news/EditNewsModal.vue' /* webpackChunkName: "pages/news/EditNewsModal" */))
const _47f3dc03 = () => interopDefault(import('../pages/news/SeenByModal.vue' /* webpackChunkName: "pages/news/SeenByModal" */))
const _8e6142a6 = () => interopDefault(import('../pages/profiles/create.vue' /* webpackChunkName: "pages/profiles/create" */))
const _78f85a94 = () => interopDefault(import('../pages/profiles/pending.vue' /* webpackChunkName: "pages/profiles/pending" */))
const _0eed4ce1 = () => interopDefault(import('../pages/profiles/update-mixin.js' /* webpackChunkName: "pages/profiles/update-mixin" */))
const _2fb03435 = () => interopDefault(import('../pages/rejection-categories/new.vue' /* webpackChunkName: "pages/rejection-categories/new" */))
const _6bfe2c25 = () => interopDefault(import('../pages/triggers/manage.vue' /* webpackChunkName: "pages/triggers/manage" */))
const _57695707 = () => interopDefault(import('../pages/triggers/pending.vue' /* webpackChunkName: "pages/triggers/pending" */))
const _75c876e7 = () => interopDefault(import('../pages/update-stats/AddNewFeature.vue' /* webpackChunkName: "pages/update-stats/AddNewFeature" */))
const _40e3bd87 = () => interopDefault(import('../pages/account-requests/_request.vue' /* webpackChunkName: "pages/account-requests/_request" */))
const _775c8c23 = () => interopDefault(import('../pages/activity-logs/_type.vue' /* webpackChunkName: "pages/activity-logs/_type" */))
const _55daa532 = () => interopDefault(import('../pages/conversations/_id.vue' /* webpackChunkName: "pages/conversations/_id" */))
const _47350181 = () => interopDefault(import('../pages/my-team/_operator/index.vue' /* webpackChunkName: "pages/my-team/_operator/index" */))
const _a50531f4 = () => interopDefault(import('../pages/profile-duplicates/_country.vue' /* webpackChunkName: "pages/profile-duplicates/_country" */))
const _5616db1e = () => interopDefault(import('../pages/profiles/_profile/index.vue' /* webpackChunkName: "pages/profiles/_profile/index" */))
const _40238fc8 = () => interopDefault(import('../pages/profiles/_profileold.vue' /* webpackChunkName: "pages/profiles/_profileold" */))
const _40fdd739 = () => interopDefault(import('../pages/triggers/_trigger.vue' /* webpackChunkName: "pages/triggers/_trigger" */))
const _e6df24aa = () => interopDefault(import('../pages/my-team/_operator/edit.vue' /* webpackChunkName: "pages/my-team/_operator/edit" */))
const _3dc43a80 = () => interopDefault(import('../pages/my-team/_operator/lock-logs.vue' /* webpackChunkName: "pages/my-team/_operator/lock-logs" */))
const _40a66f76 = () => interopDefault(import('../pages/my-team/_operator/message-list.vue' /* webpackChunkName: "pages/my-team/_operator/message-list" */))
const _d326163a = () => interopDefault(import('../pages/my-team/_operator/profile-list.vue' /* webpackChunkName: "pages/my-team/_operator/profile-list" */))
const _061b49b4 = () => interopDefault(import('../pages/my-team/_operator/trigger-list.vue' /* webpackChunkName: "pages/my-team/_operator/trigger-list" */))
const _25a67065 = () => interopDefault(import('../pages/profiles/_profile/photos.vue' /* webpackChunkName: "pages/profiles/_profile/photos" */))
const _43bdd584 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account-requests",
    component: _6e66052b,
    name: "account-requests"
  }, {
    path: "/ai-reports",
    component: _015fe05e,
    name: "ai-reports"
  }, {
    path: "/bonus",
    component: _186b304e,
    name: "bonus"
  }, {
    path: "/boost-all",
    component: _36349063,
    name: "boost-all"
  }, {
    path: "/bug-reports",
    component: _1a5512d2,
    name: "bug-reports"
  }, {
    path: "/dashboard",
    component: _b23fbafa,
    name: "dashboard"
  }, {
    path: "/faqs",
    component: _c8ec9eb8,
    name: "faqs"
  }, {
    path: "/feedback-funnel",
    component: _720c9926,
    name: "feedback-funnel"
  }, {
    path: "/ht-alerts",
    component: _0c1099e4,
    name: "ht-alerts"
  }, {
    path: "/invoices",
    component: _7281c3a6,
    name: "invoices"
  }, {
    path: "/journal-edits",
    component: _4611667c,
    name: "journal-edits"
  }, {
    path: "/lock-logs",
    component: _382fcffd,
    name: "lock-logs"
  }, {
    path: "/login",
    component: _b06e8f56,
    name: "login"
  }, {
    path: "/manager-stats",
    component: _dea792a4,
    name: "manager-stats"
  }, {
    path: "/manuals",
    component: _56c7b31c,
    name: "manuals"
  }, {
    path: "/message-reports",
    component: _0373c5e5,
    name: "message-reports"
  }, {
    path: "/messages",
    component: _0f815a90,
    name: "messages"
  }, {
    path: "/missed-payments",
    component: _7cce454e,
    name: "missed-payments"
  }, {
    path: "/my-team",
    component: _9c22eb66,
    name: "my-team"
  }, {
    path: "/news",
    component: _daa4104c,
    name: "news"
  }, {
    path: "/notifications",
    component: _28367254,
    name: "notifications"
  }, {
    path: "/operator-details",
    component: _786a85a0,
    name: "operator-details"
  }, {
    path: "/operator-traffic-dashboard",
    component: _f385503c,
    name: "operator-traffic-dashboard"
  }, {
    path: "/payment-updates",
    component: _6f6b02ef,
    name: "payment-updates"
  }, {
    path: "/pl-stats",
    component: _35af7a56,
    name: "pl-stats"
  }, {
    path: "/profile",
    component: _58ae64d6,
    name: "profile"
  }, {
    path: "/profiles",
    component: _784b3231,
    name: "profiles"
  }, {
    path: "/rejection-categories",
    component: _36c8bf27,
    name: "rejection-categories"
  }, {
    path: "/settings",
    component: _28b9e1ac,
    name: "settings"
  }, {
    path: "/stats",
    component: _54f934ce,
    name: "stats"
  }, {
    path: "/top-triggers",
    component: _4a65dc3a,
    name: "top-triggers"
  }, {
    path: "/traffic-stats",
    component: _1812ae04,
    name: "traffic-stats"
  }, {
    path: "/triggers",
    component: _9490d27c,
    name: "triggers"
  }, {
    path: "/update-stats",
    component: _54c504bc,
    name: "update-stats"
  }, {
    path: "/wink-replies",
    component: _6fb67d91,
    name: "wink-replies"
  }, {
    path: "/manuals/new",
    component: _b954bd2c,
    name: "manuals-new"
  }, {
    path: "/my-team/add",
    component: _3cee1148,
    name: "my-team-add"
  }, {
    path: "/my-team/pending",
    component: _21b193d2,
    name: "my-team-pending"
  }, {
    path: "/news/CreateNewsModal",
    component: _34723ae6,
    name: "news-CreateNewsModal"
  }, {
    path: "/news/EditNewsModal",
    component: _6072a898,
    name: "news-EditNewsModal"
  }, {
    path: "/news/SeenByModal",
    component: _47f3dc03,
    name: "news-SeenByModal"
  }, {
    path: "/profiles/create",
    component: _8e6142a6,
    name: "profiles-create"
  }, {
    path: "/profiles/pending",
    component: _78f85a94,
    name: "profiles-pending"
  }, {
    path: "/profiles/update-mixin",
    component: _0eed4ce1,
    name: "profiles-update-mixin"
  }, {
    path: "/rejection-categories/new",
    component: _2fb03435,
    name: "rejection-categories-new"
  }, {
    path: "/triggers/manage",
    component: _6bfe2c25,
    name: "triggers-manage"
  }, {
    path: "/triggers/pending",
    component: _57695707,
    name: "triggers-pending"
  }, {
    path: "/update-stats/AddNewFeature",
    component: _75c876e7,
    name: "update-stats-AddNewFeature"
  }, {
    path: "/account-requests/:request?",
    component: _40e3bd87,
    name: "account-requests-request"
  }, {
    path: "/activity-logs/:type?",
    component: _775c8c23,
    name: "activity-logs-type"
  }, {
    path: "/conversations/:id?",
    component: _55daa532,
    name: "conversations-id"
  }, {
    path: "/my-team/:operator?",
    component: _47350181,
    name: "my-team-operator"
  }, {
    path: "/profile-duplicates/:country?",
    component: _a50531f4,
    name: "profile-duplicates-country"
  }, {
    path: "/profiles/:profile",
    component: _5616db1e,
    name: "profiles-profile"
  }, {
    path: "/profiles/:profileold",
    component: _40238fc8,
    name: "profiles-profileold"
  }, {
    path: "/triggers/:trigger",
    component: _40fdd739,
    name: "triggers-trigger"
  }, {
    path: "/my-team/:operator?/edit",
    component: _e6df24aa,
    name: "my-team-operator-edit"
  }, {
    path: "/my-team/:operator?/lock-logs",
    component: _3dc43a80,
    name: "my-team-operator-lock-logs"
  }, {
    path: "/my-team/:operator?/message-list",
    component: _40a66f76,
    name: "my-team-operator-message-list"
  }, {
    path: "/my-team/:operator?/profile-list",
    component: _d326163a,
    name: "my-team-operator-profile-list"
  }, {
    path: "/my-team/:operator?/trigger-list",
    component: _061b49b4,
    name: "my-team-operator-trigger-list"
  }, {
    path: "/profiles/:profile/photos",
    component: _25a67065,
    name: "profiles-profile-photos"
  }, {
    path: "/",
    component: _43bdd584,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
